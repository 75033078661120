<template>
  <div class="prices">
    <h2 class="hidden">Фотограф Краснодар цены</h2>
    <div class="prices_top_notes text-start">
    </div>

    <div class="prices_groups">

      <div class="prices_group">
        <div class="prices_group_title mb-3 mt-2">
        </div>
        <div class="prices_cards ms-auto me-auto">
          <div class="card mb-3">
            <div class="card-body d-flex flex-row flex-wrap flex-lg-nowrap justify-content-around ms-5 me-5">
              <div class="me-2">
                <h3 class="card-title badge bg-dark mb-4"> Мини </h3>
                <div class="mb-5">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item ">фотосессия 30 минут</li>
                    <li class="list-group-item">аренда студии</li>
                    <li class="list-group-item">60 фотографий в цветокоррекции</li>
                    <li class="list-group-item">помощь в позировании</li>
                    <li class="list-group-item mt-3">ЦЕНА: 5000 рублей</li>
                  </ul>
                </div>
              </div>

              <div class="me-2">
                <h3 class="card-title badge bg-dark mb-4"> Стандарт </h3>
                <div class="mb-5">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">фотосессия 30 минут</li>
                    <li class="list-group-item">аренда студии</li>
                    <li class="list-group-item">от 60 фотографий в цветокоррекции</li>
                    <li class="list-group-item">5 фото в ретуши</li>
                    <li class="list-group-item">аренда до 2 образов из моего проката</li>
                    <li class="list-group-item">помощь в позировании</li>
                    <li class="list-group-item mt-3">ЦЕНА: 6000 рублей</li>
                  </ul>
                </div>
              </div>         
              
              <div class="me-2">
                <h3 class="card-title badge bg-dark mb-4"> Всё включено </h3>
                <div class="mb-5">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">фотосессия 30 минут</li>
                    <li class="list-group-item">аренда студии</li>
                    <li class="list-group-item">аренда гримерки за час до фотосессии</li>
                    <li class="list-group-item">от 60 фотографий в цветокоррекции</li>
                    <li class="list-group-item">5 фото в ретуши</li>
                    <li class="list-group-item">макияж и локоны</li>
                    <li class="list-group-item">аренда до 2 образов из моего проката</li>
                    <li class="list-group-item">помощь в позировании</li>
                    <li class="list-group-item mt-3">ЦЕНА: 7500 рублей</li>
                  </ul>
                </div>
              </div> 

            </div>
          </div>

          <div class="card mb-3">
            <div class="card-body d-flex flex-row flex-wrap flex-md-nowrap justify-content-around ms-5 me-5">
              <div class="me-2">
                <h3 class="card-title badge bg-dark mb-4"> Комфорт </h3>
                <div class="mb-5">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item ">фотосессия 55 минут</li>
                    <li class="list-group-item ">аренда платьев / боди из моего проката</li>
                    <li class="list-group-item">помощь в позировании</li>
                    <li class="list-group-item">все хорошие фотографии в цветокоррекции (100-120шт)</li>
                    <li class="list-group-item">10 фотографий в ретуши</li>
                    <li class="list-group-item">готовые фотографии в цветокоррекции уже через 10 дней</li>
                    <li class="list-group-item">аренда студии оп</li>
                    <li class="list-group-item mt-3">
                      ЦЕНА: 8500 рублей <br>
                      (Аренда студии оплачивается дополнительно 1400-2400 руб.)
                    </li>
                  </ul>
                </div>
              </div>

              <div class="me-2">
                <h3 class="card-title badge bg-dark mb-4"> Премиум </h3>
                <div class="mb-5">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">фотосессия 55 минут</li>
                    <li class="list-group-item">аренда стильной фотостудии (студию можно выбрать любую)</li>
                    <li class="list-group-item">аренда гримерки за час до фотосессии</li>
                    <li class="list-group-item">Создание образа (макияж + причёска)</li>
                    <li class="list-group-item">Помощь в позировании</li>
                    <li class="list-group-item">Аренда платьев/боди из моего проката</li>
                    <li class="list-group-item">Все хорошие фотографии в цветокоррекции (100-120 шт.)</li>
                    <li class="list-group-item">10 фотографий в ретуши</li>
                    <li class="list-group-item">Готовые фотографии в цветокоррекции уже через 10 дней</li>
                    <li class="list-group-item mt-3">Стоимость 13000 руб.</li>
                  </ul>
                </div>
              </div>         

            </div>
          </div>
        </div>
      </div>

      <div class="prices_group">
        <div class="prices_cards ms-auto me-auto">
          <div class="card mb-3">
            <vueper-slides 
              3d
              autoplay
              :bullets="false" 
              slide-image-inside 
              no-shadow 
              fixed-height="450px" 
              :visible-slides="3"
              slide-multiple
              :gap="3" 
              :breakpoints="{ 1500: { visibleSlides: 1, slideMultiple: 1 } }" 
              class="no-shadow"
            >
              <vueper-slide key="premium1" title="" :image="require('@/assets/images/comfort/comfort1.jpg')"/>
              <vueper-slide key="premium2" title="" :image="require('@/assets/images/premium/premium2.jpg')"/>
              <vueper-slide key="premium3" title="" :image="require('@/assets/images/premium/premium3.jpg')"/>
              <vueper-slide key="premium4" title="" :image="require('@/assets/images/premium/premium4.jpg')"/>
              <vueper-slide key="premium5" title="" :image="require('@/assets/images/premium/premium5.jpg')"/>
              <vueper-slide key="premium6" title="" :image="require('@/assets/images/premium/premium6.jpg')"/>
              <vueper-slide key="premium7" title="" :image="require('@/assets/images/premium/premium7.jpg')"/>
              <vueper-slide key="premium8" title="" :image="require('@/assets/images/premium/premium8.jpg')"/>
              <vueper-slide key="premium9" title="" :image="require('@/assets/images/premium/premium9.jpg')"/>
              <vueper-slide key="premium10" title="" :image="require('@/assets/images/premium/premium10.jpg')"/>
              <vueper-slide key="premium11" title="" :image="require('@/assets/images/premium/premium11.jpg')"/>
              <vueper-slide key="premium12" title="" :image="require('@/assets/images/premium/premium12.jpg')"/>
              <vueper-slide key="premium13" title="" :image="require('@/assets/images/premium/premium13.jpg')"/>
              <vueper-slide key="premium14" title="" :image="require('@/assets/images/premium/premium14.jpg')"/>
              <vueper-slide key="premium15" title="" :image="require('@/assets/images/premium/premium15.jpg')"/>
              <vueper-slide key="premium16" title="" :image="require('@/assets/images/premium/premium16.jpg')"/>
              <vueper-slide key="premium17" title="" :image="require('@/assets/images/premium/premium17.jpg')"/>
              <vueper-slide key="premium18" title="" :image="require('@/assets/images/premium/premium18.jpg')"/>
              <vueper-slide key="premium19" title="" :image="require('@/assets/images/premium/premium19.jpg')"/>
              <vueper-slide key="premium20" title="" :image="require('@/assets/images/premium/premium20.jpg')"/>
              <vueper-slide key="premium21" title="" :image="require('@/assets/images/premium/premium21.jpg')"/>
            </vueper-slides>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
  name: 'Prices',
  metaInfo: {
    title: 'Фотограф Краснодар цены (недорого)',
    meta: [
      { vmid: 'description_price', property: 'description', name: 'description', content: 'Фотография беременности Краснодар' },
    ],
  },
  components: { VueperSlides, VueperSlide },
  data() {
    return{
      minimalizm: {
        title: 'Минимализм',
        image: 'minimalizm.jpg',
        data: {
          first: {
            price: 3200,
            title: 'some title one'

          }
        }
      }
    }
  },
  /* mounted(){
    document.title = 'Фотосессия беременных в Краснодаре цены';
  }, */
  methods: {
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/mixins/_breakpoints.scss";
 @font-face {
  font-family: "Jost";
  src: local("Jost"),   url(../fonts/Jost-Regular.ttf) format("truetype");
  }

  .vueperslide__image {
    background-size: contain;
    background-repeat: no-repeat;
}
  .vueperslides__arrow {
    color: rgb(64, 100, 110);
  }

  .prices{
    font-family: Jost;

    .hidden {
      visibility: hidden;
    }

   // border: 1px solid green;
    &_main_title{
      font-family: Jost;
    }
    &_top_notes{}
    &_groups{
      //border: 1px solid blue;
    }
    &_group{
      //border: 1px solid red;
      margin-left: auto;
      margin-right: auto;
      &_title{
        font-size: 36px;
        font-weight: 700;
      }
      .prices_cards{
        width: 90%;
        @include media-breakpoint-down(sm) { 
          width: 100%;
        }
        .card{
          background: transparent;
          -webkit-box-shadow: 6px 11px 25px 17px rgba(116, 185, 238, 0.1);
          -moz-box-shadow: 6px 11px 25px 17px rgba(116, 185, 238, 0.1);
          box-shadow: 6px 11px 25px 17px rgba(116, 185, 238, 0.1);
          &-title{
            font-size: 32px;
            @include media-breakpoint-down(lg) { 
              font-size: 22px;
            }
            @include media-breakpoint-down(md) { 
              font-size: 18px;
            }
            @include media-breakpoint-down(sm) { 
              font-size: 16px;
            }
          }
          &-body{
            background: white;
          }
          .list-group{
            &-item{
              font-size: 20px;
              &:first-letter {
              text-transform: uppercase;
          }
            }
          }
        }
      }
    }
  }
</style>
